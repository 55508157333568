















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Investment, PaymentStatus } from '@/interfaces/investment'
import { Watch } from 'vue-property-decorator'
import { VMoney } from 'v-money'
import JsonExcel from 'vue-json-excel'

import { groupBy } from 'underscore'

import currency from 'currency.js'

import { mapGetters } from 'vuex'

interface Recurring {
  email: string;
  total: number;
  average: number;
  sum: number;
}

@Component({
  components: { JsonExcel },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      investments: 'investment/investments',
      loading: 'investment/loading'
    })
  }
})
export default class RecurringInvestors extends Vue {
  investments!: Investment[];
  loading!: boolean

  $moment;

  investors: Recurring[] = []

  form = {
    totalInvested: 0,
    average: 0,
    size: 100,
    totalSize: 0
  }

  formState = {
    loading: false,
    downloadLoading: false
  };

  RecurringToCSV = {
    Email: 'email',
    'Total Aportado': {
      field: 'sum',
      callback: (value) => this.$options.filters?.currency(value)
    },
    'Ticket Médio': {
      field: 'average',
      callback: (value) => this.$options.filters?.currency(value)
    },
    'Qtd. de Investimentos': 'total'
  }

  /**
   * Currency configuration to use v-money directive.
   */
  money = {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false /* doesn't work with directive */
  }

  @Watch('investments')
  onInvestmentsChange(cur: Investment[]) {
    if (!cur.length) return

    this.investors = this.findRecurringInvestors(this.paidInvestments, this.form.size)
  }

  get paidInvestments() {
    return this.investments.filter(
      (i) => PaymentStatus[i.pagamento.status] === PaymentStatus.PAGO
    )
  }

  sumOfInvestments(investments: Investment[]): number {
    return investments.reduce((acc, cur) => {
      acc += cur.valor
      return acc
    }, 0)
  }

  findRecurringInvestors(investments: Investment[], size: number, sum = 0, average = 0, total = 2): Recurring[] {
    const groupedByEmail = groupBy(investments, (i) => i.investidor.email)

    const data = Object.keys(groupedByEmail)
      .map((email) => {
        const sum = this.sumOfInvestments(groupedByEmail[email])
        const total = groupedByEmail[email].length
        const average = sum / total

        const isRecurring = groupBy(groupedByEmail[email], (i) => i.url_oferta)

        return {
          email,
          total,
          average,
          sum,
          isRecurring: Object.keys(isRecurring).length > 1
        }
      })
      .filter((i) => i.isRecurring)
      .filter((i) => i.sum >= sum)
      .filter((i) => i.average >= average)
      .filter((i) => i.total >= total)
      .sort((a, b) => b.total - a.total)

    this.form.totalSize = data.length
    return data.slice(0, size)
  }

  totalRecurringInvestors() {
    const ONE_MILION = 1000000

    return this.findRecurringInvestors(this.paidInvestments, ONE_MILION).length
  }

  biggestAverage() {
    const ONE_MILION = 1000000

    const biggest = this.findRecurringInvestors(this.paidInvestments, ONE_MILION)
      .sort((a, b) => b.average - a.average)[0]

    if (biggest) return biggest
  }

  biggestInvoice() {
    const biggest = this.paidInvestments.sort((a, b) => b.valor - a.valor)[0]
    if (biggest) return biggest
  }

  onFormSubmit() {
    this.formState.loading = true

    const totalInvested = currency(this.form.totalInvested, {
      separator: '.',
      decimal: ','
    }).value

    const average = currency(this.form.average, {
      separator: '.',
      decimal: ','
    }).value

    // Esse delay permite que o componente inner-content consiga bloquear a tela.
    setTimeout(() => {
      this.investors = this.findRecurringInvestors(
        this.paidInvestments,
        this.form.size,
        totalInvested,
        average
      )

      this.formState.loading = false
    }, 100)
  }

  async fetchData() {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.investors)
      }, 100)
    })
  }

  startDownload() {
    this.formState.downloadLoading = true
  }

  finishDownload() {
    this.formState.downloadLoading = false
  }
}
