






















































import Vue from 'vue'
import Component from 'vue-class-component'
import RevenuesByMonth from '@/components/charts/RevenuesByMonth.vue'
import AverageInvestmentByMonth from '@/components/charts/AverageInvestmentByMonth.vue'
import NewInvestorsByMonth from '@/components/charts/NewInvestorsByMonth.vue'
import RecurringInvestors from '@/components/charts/RecurringInvestors.vue'
import InvestmentsByPaymentStatusByMonth from '@/components/charts/InvestmentsByPaymentStatusByMonth.vue'
import JsonExcel from 'vue-json-excel'
import { VueMaskDirective } from 'v-mask'
import { Investment } from '@/interfaces/pRest/investment'
import { mapGetters } from 'vuex'

@Component({
  components: {
    RevenuesByMonth,
    AverageInvestmentByMonth,
    NewInvestorsByMonth,
    RecurringInvestors,
    InvestmentsByPaymentStatusByMonth,
    JsonExcel
  },
  computed: {
    ...mapGetters('investment', ['investments', 'loading'])
  },
  directives: {
    mask: VueMaskDirective
  }
})
export default class AnalysisPage extends Vue {
  $moment;

  investments!: Investment[]

  state = {
    loading: false,
    error: null,
    downloadLoading: false
  }

  async mounted() {
    this.$store.dispatch('investment/load')
  }

  fetchInvestments() {
    this.$store.dispatch('investment/load')
  }
}

