



























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import * as lang from 'vuejs-datepicker/src/locale'

import { Investment, PaymentStatus } from '@/interfaces/investment'
import { Watch } from 'vue-property-decorator'

import { groupBy, flatten } from 'underscore'

import { mapGetters } from 'vuex'

interface PageState {
  loading: boolean;
}

interface GroupedInvestment {
  email: string;
  offers: string[];
  investmentValues: string[];
  investmentDates: string[];
}

@Component({
  computed: {
    ...mapGetters({
      investments: 'investment/investments',
      loading: 'investment/loading'
    })
  }
})
export default class NewInvestorsByMonth extends Vue {
  investments!: Investment[];

  $moment;

  languages = lang

  investors: GroupedInvestment[] = []

  form: { query: null | Date } = {
    query: null
  }

  formState = {
    loading: false,
    downloadLoading: false
  };

  NewInvestorsToCSV = {
    Email: 'email',
    Ofertas: {
      field: 'offers',
      callback: (value) => value.join(', ')
    },
    'Valores dos Investimentos': {
      field: 'investmentValues',
      callback: (values) => values.map(v => this.$options.filters?.currency(v)).join(', ')
    },
    'Data dos Investimentos': {
      field: 'investmentDates',
      callback: (values) => values.join(', ')
    }
  }

  @Watch('investments')
  onInvestmentsChange(cur: Investment[]) {
    if (!cur.length) return

    this.investors = this.searchForNewInvestors(this.paidInvestments, this.form.query as Date)
  }

  get paidInvestments() {
    return this.investments.filter(
      (i) => PaymentStatus[i.pagamento.status] === PaymentStatus.PAGO
    )
  }

  async mounted() {
    this.form.query = this.$moment().startOf('month').toDate()
    this.investors = this.searchForNewInvestors(this.paidInvestments, this.form.query as Date)
  }

  sumOfNewInvestors(investors: GroupedInvestment[]) {
    const values = flatten(investors.map(i => i.investmentValues))

    return values.reduce((acc, cur) => {
      acc += cur
      return acc
    }, 0)
  }

  distinctOffers(investors: GroupedInvestment[]) {
    const offers = flatten(investors.map(i => i.offers))

    const offerCount = offers.reduce((acc, cur) => {
      acc[cur] = acc[cur] ? acc[cur] + 1 : 1
      return acc
    }, {})

    return Object.keys(offerCount)
      .map((o) => {
        return `${o} (${offerCount[o]})`
      })
      .join('<br>')
  }

  searchForNewInvestors(investments: Investment[], date: Date): GroupedInvestment[] {
    const selectedMonthAndYear = this.$moment(date).format('MM/YYYY')

    // Pega os investimentos do mês selecionado
    const monthInvestments = investments.filter((i) => {
      return this.$moment(i.pagamento.criado).format('MM/YYYY') === selectedMonthAndYear
    })

    // Pega tudo que não faz parte do array de cima.
    const remainingInvestments = investments.filter((i) => {
      return this.$moment(i.pagamento.criado).format('MM/YYYY') !== selectedMonthAndYear
    })

    const newInvestors: any[] = []

    // Para cada investimento do mês selecionado, procura se existe outro investimento fora do período do mesmo investidor.
    // Caso exista, então não é um investidor novo.
    monthInvestments.forEach((i) => {
      const findAnotherInvestiment = remainingInvestments
        .filter((r) => r.investidor.email === i.investidor.email)
        .filter((r) => {
          const investmentDate = this.$moment(i.pagamento.criado)
          const remainingDate = this.$moment(r.pagamento.criado)

          return investmentDate.isAfter(remainingDate)
        })

      if (!findAnotherInvestiment.length) {
        newInvestors.push(i)
      }
    })

    const grouped = groupBy(newInvestors, (i) => i.investidor.email)

    return Object.keys(grouped).map((email) => {
      return {
        email,
        offers: grouped[email].map((i) => i.url_oferta),
        investmentValues: grouped[email].map((i) => i.valor),
        investmentDates: grouped[email].map((i) => this.$moment(i.pagamento.criado).format('DD/MM/YYYY'))
      }
    })
  }

  onFormSubmit() {
    if (!this.investments.length) return

    this.formState.loading = true

    // Esse timeout é para dar tempo do innercontent bloquear a tela
    setTimeout(() => {
      this.investors = this.searchForNewInvestors(this.paidInvestments, this.form.query as Date)
      this.formState.loading = false
    }, 100)
  }

  fetchData() {
    return this.investors
  }

  startDownload() {
    this.formState.downloadLoading = true
  }

  finishDownload() {
    this.formState.downloadLoading = false
  }
}
