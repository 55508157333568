























































import Vue from 'vue'
import Component from 'vue-class-component'

import { ChartType } from 'chart.js/dist/Chart.js'

import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
import { Damask6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office'

import { Investment, PaymentStatus } from '@/interfaces/investment'
import { Watch } from 'vue-property-decorator'
import * as Chart from 'chart.js/dist/Chart.js'

import { mapGetters } from 'vuex'

const ChartJS = (window as any).Chart

interface RevenuesOptions {
  selectedYears: number[];
}

@Component({
  computed: {
    ...mapGetters({
      investments: 'investment/investments',
      loading: 'investment/loading'
    })
  }
})
export default class AverageInvestmentByMonth extends Vue {
  $moment;

  investments!: Investment[]
  loading!: boolean
  averageChart!: ChartType

  years: number[] = [2018, 2019, 2020, 2021]

  options: RevenuesOptions = {
    selectedYears: [2018, 2019, 2020, 2021]
  }

  labels: string[] = []

  datasets: Chart.ChartDataSets[] = []

  formState = {
    loading: false
  }

  @Watch('investments')
  onInvestmentsChange(cur: Investment[]) {
    if (!cur.length) return

    this.datasets = this.buildDatasets(this.paidInvestments, this.options.selectedYears)

    this.renderChart(this.averageChart, this.labels, this.datasets)
  }

  get paidInvestments() {
    return this.investments.filter((i) => PaymentStatus[i.pagamento.status] === PaymentStatus.PAGO)
  }

  async mounted() {
    this.labels = this.buildLabels()

    this.initaverageChart()
  }

  renderChart(chart, labels, datasets) {
    chart.data.labels = labels
    chart.data.datasets = datasets

    chart.update()
  }

  initaverageChart() {
    const ctx = (this.$refs.averageChart as any).getContext('2d')

    this.averageChart = new ChartJS(ctx, {
      type: 'line',
      options: {
        plugins: {
          colorschemes: {
            scheme: Damask6
          }
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetIndex = tooltipItem.datasetIndex
              const datasetLabel = data.datasets[datasetIndex].label

              const label = this.$options.filters?.currency(tooltipItem.yLabel)
              return `${datasetLabel} - ${label}`
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            // stacked: true,
            ticks: {
              beginAtZero: true,
              fontSize: 16,
              padding: 20,
              callback: (value) => {
                return this.$options.filters?.currency(value)
              }
            }
          }]
        }
      }
    })
  }

  buildDatasets(investments: Investment[], years: number[]) {
    return years.map((year) => {
      const invs = investments.filter((i) => {
        return this.$moment(i.pagamento.criado).year() === year
      })

      const data: number[] = this.labels.map((month: string) => {
        const currentMonthInvs = invs.filter(i => {
          const paidMonth = this.$moment(i.pagamento.criado).format('MMM')
          return paidMonth === month
        })

        const sumOfMonth = currentMonthInvs.reduce((acc, cur) => {
          acc += cur.valor
          return acc
        }, 0)

        return (sumOfMonth / currentMonthInvs.length)
      })

      return {
        label: year.toString(),
        data,
        lineTension: 0.1
      }
    })
  }

  async onFormSubmit() {
    this.formState.loading = true

    setTimeout(() => {
      this.datasets = this.buildDatasets(this.paidInvestments, this.options.selectedYears)

      this.renderChart(this.averageChart, this.labels, this.datasets)

      this.formState.loading = false
    }, 100)
  }

  buildLabels() {
    return this.$moment.monthsShort()
  }
}
